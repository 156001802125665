import * as React from "react";
import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

// Components
import Seo from "../components/SEO";
import Layout from "../components/Layout";
// Assets
import Illustration from "../images/icons/not_found.svg";

// Styles
import * as styles from "../styles/pages/404.module.css";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className={styles.sectionContainer}>
        <div className={styles.illustration}>
          <Illustration />
        </div>
        <div>
          <h1>{t("404.title")}</h1>
          <p>{t("404.description")}</p>
          <Link className="button button--secondary" to="/">
            {t("404.button")}
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "404"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const Head = ({ pageContext }) => {
  const isArabic = pageContext.i18n.language === "ar";
  return <Seo isArabic={isArabic} title={isArabic ? "404" : "404"} />;
};
